<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
		<div class="home">
			<div @click="go('My')">我的主页</div>
			<div><</div>
					<div style="color: #E66617;">我的购物车</div>
			</div>
			<div class="orderList">
				<div class="orderList-content borderbottom">
				 <el-select v-model="type" placeholder="选择分区" style="width:40%;" @change="getUserCart">
						<el-option :label="item.typename" :value="item.id" v-for="(item,index) in cate" :key="index">
						</el-option>
					</el-select>
					<div class="orderList-list information">
						<div style="display: flex;align-items: center;">
							<el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"
								@change="handleCheckAllChange"></el-checkbox>
							<div style="margin-left: 10px;">全选</div>
						</div>
						<div>价格</div>
						<div>操作</div>
					</div>
				</div>
				<div class="orderList-content">
					<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
						<el-checkbox  v-for="(item,index) in tableData" :label="item" :key="index">
							<div class="orderList-list content">
								<div>
									<div style="display: flex;align-items: center;">
										
										<img :src="imageUrl(item.litpic)" alt="">
									</div>
							
									<div style="margin-top: 10px;margin-left: 20px;">编号<span style="margin-left: 5px;">{{ item.product_id }}</span></div>
								</div>
								<div class="money">￥{{ item.cost }}</div>
								<div class="confirm" @click="handleDelete(item.cart_id)">删除</div>
							</div>
						</el-checkbox>
						
					</el-checkbox-group>

					<div class="buy">
						<div>已选作品{{ num }}幅，合计<span style="color: #E66617;">￥{{ money }}.00</span></div>
						<div class="button">
							<div @click="del">删除</div>
							<div @click="isShow = true">立即购买</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 购买弹框 -->
			<el-dialog title="提示" :visible.sync="isShow" width="80%"style="margin-top: 100px;" center>
				<div class="govip">确定结算吗？</div>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="settle" style="background: #0086D9; border: none; width: 100px">确 定
					</el-button>
					<el-button @click="isShow = false" style="
			        background: #c92323;
			        color: #ffffff;
			        border: none;
			        width: 100px;
			      ">取 消</el-button>
				</span>
			</el-dialog>
			<info />
			<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
			<foot />
		</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import {
		userCart,
		joinCart,
		cartSourceDetailorder,
		topCate
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				tableData: [],
				multipleSelection: [],
				key_words: "", //作品名称编号搜索
				type: "", //所属区域
				count: 1,
				size: 999,
				page: 1,
				money: 0, //总价钱
				num: 0,
				shopActive: [],
				cate: '',
				checkAll: false,
				checkedCities:[],
				isIndeterminate: true,
				isShow:false,
			};
		},

		created() {
			this.getCata()
			this.getUserCart()
		},
		methods: {
			handleCheckedCitiesChange(value) {
				this.money = 0
				this.shopActive = []
				this.multipleSelection = []
				console.log(value);
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.tableData.length;
				this.num = value.length
				value.forEach(a=>{
					this.money += Number(a.cost)
					this.shopActive.push(a.product_id) 
					this.multipleSelection.push(a.cart_id)
				})
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length;
			},
			handleCheckAllChange(val) {
				this.money = 0
				this.shopActive = []
				this.multipleSelection = []
				this.checkedCities = val ? this.tableData : [];
				this.isIndeterminate = false;
				this.checkedCities.forEach(a=>{
					this.money += Number(a.cost)
					this.shopActive.push(a.product_id) 
					this.multipleSelection.push(a.cart_id)
				})
				this.num = this.checkedCities.length
			},
			getUserCart() {
				userCart({
					key_words: this.key_words,
					type: this.type,
					page: this.page,
					size: this.size,
				}).then((res) => {
					if (res.code == 200) {
						// console.log(res.data)
						this.tableData = res.data.cartInfo;
						this.count = res.data.count;
					}
				});
			},
			//获取顶部分区
			getCata() {
				topCate().then((res) => {
					// console.log(res);
					this.cate = res.data;
				});
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
			//结算
			
			settle() {
			  if(this.shopActive == '' || this.shopActive == null) return
			  
			    cartSourceDetailorder({
			      card_id: this.shopActive.toString(),
			    }).then((res) => {
					console.log(res);
			      if (res.code == 200) {
			        this.$message({
			          message: res.msg,
			          type: "success",
			        });
			        this.$router.go(0)
			      } else {
			        this.$message.error(res.msg);
			      }
			    });
			},
			//单删
			handleDelete(row) {
			
			  joinCart({
			    cart_id: row,
			  }).then((res) => {
			    console.log(res);
			    if (res.code == 200) {
			      this.$message({
			        message: "操作成功",
			        type: "success",
			      });
			
			      this.getUserCart();
			    }
			  });
			},
			//多删
			del() {
			
			  if(this.multipleSelection == '') return
			  joinCart({
			    cart_id: this.multipleSelection.toString(),
			  }).then((res) => {
			    console.log(res);
			    if (res.code == 200) {
			      this.$message({
			        message: "操作成功",
			        type: "success",
			      });
			
			      this.getUserCart();
			    }
			  });
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;

		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;

			div {
				margin-right: 7px;
			}
		}

		.orderList {
			margin-top: 15px;
			padding: 0 10px;

			.orderList-content {
				padding: 18px 12px;

				font-size: 13px;
				background-color: #FFFFFF;
				color: #000000;
			}

			.borderbottom {
				border-bottom: 1px solid #ECECEC;
			}

			.orderList-list {
				display: flex;
				align-items: center;
				justify-content: space-between;

				img {
					margin-left: 10px;
					width: 100px;
					height: 100px;
				}

				.money {
					position: absolute;
					left: 55%;
					transform: translateX(-55%)
				}
			}

			.information {
				margin-top: 16px;
				padding-right: 20px;
			}

			.content {
				width: 300px;
				border-bottom: 1px solid #EFEFEF;
				padding-bottom: 15px;
				margin-bottom: 15px;
				color: #08243C;

				.confirm {
					color: #C92323;
					border-radius: 12px;
					padding: 6px 21px;
					font-size: 12px;
					border: 1px #C92323 solid;
				}
			}

			.buy {
				margin-top: 20px;
				text-align: right;

				.button {
					margin-top: 23px;
					display: flex;
					flex-direction: row-reverse;

					div:last-child {
						margin-right: 12px;
						width: 75px;
						height: 26px;
						background-color: #E66617;
						color: #FFFFFF;
						line-height: 26px;
						text-align: center;
						border-radius: 3px;
					}

					div:first-child {
						width: 65px;
						height: 24px;
						border: 1px solid #C92323;
						color: #C92323;
						line-height: 26px;
						text-align: center;
						border-radius: 3px;
					}
				}
			}
		}
		.govip {
			font-size: 13px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			text-align: center;
			margin-top: 15px;
		}

	}
</style>
